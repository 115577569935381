<template lang="">
    <div class="m-3">
        <b-form @submit.prevent="goProcess">
            <b-card :header="'ຄຳຖາມ.... (ທ່ານ '+fld.fname+' '+fld.lname+', ອາຍຸ '+$route.params.age+')'">
                <div v-show="fld.age<15">
                    <b-list-group>
                        <b-list-group-item>
                            <b-form-checkbox switch v-model="fld.ca1" @change="oncheckvalid" required>- ທ່ານມີໃບອະນຸຍາດຂອງຜູ້ປົກຄອງແລ້ວບໍ່? </b-form-checkbox>
                        </b-list-group-item>
                        <b-list-group-item>
                            <b-form-checkbox switch v-model="fld.ca3" @change="oncheckvalid" required>- ສຳມະໂນຄົວມີຮູບຂອງທ່ານຕິດຢູ່ ແລະ ຈ້ຳກາ ປກສ ແລ້ວບໍ່? </b-form-checkbox>
                        </b-list-group-item>
    
                        <b-list-group-item>
                            <b-form-checkbox switch v-model="fld.ca2" @change="oncheckvalid" required>- ພໍ່ ແລະ ແມ່ຢູ່ໃນສຳມະໂນຄົວດຽວກັນກັບທ່ານບໍ່? </b-form-checkbox>
                        </b-list-group-item>
                    </b-list-group>
    
    
    
    
                </div>
    
                <br>
                <div v-show="fld.age>=15 && fld.age<=18">
                    <b-list-group>
                        <b-list-group-item>
                            <b-form-checkbox switch v-model="fld.ca8" required>- ທ່ານມີ ບັດປະຈຳຕົວ ແລະ ໃບອະນຸຍາດຜູ້ປົກຄອງ ແລ້ວບໍ່? </b-form-checkbox>
                        </b-list-group-item>
                        <!-- <b-list-group-item>
                                    <b-form-checkbox switch v-model="fld.ca9" required>- ພໍ່ແລະແມ່ຢູ່ໃນສຳມະໂນຄົວດຽວກັນກັບທ່ານບໍ່? </b-form-checkbox>
                                </b-list-group-item>
                                <b-list-group-item>
                                    <b-form-checkbox switch v-model="fld.ca10" required>- ສຳມະໂນຄົວມີຮູບຂອງທ່ານຕິດຢູ່ ແລະ ຈຳກາ ປກສ ແລ້ວບໍ່? </b-form-checkbox>
                                </b-list-group-item> -->
                        <b-list-group-item>
    
                            <label>ວັນທີ່ໝົດອາຍຸບັດປະຈຳຕົວ</label>
                            <b-input-group>
                                <b-form-datepicker v-model="fld.idcardexpriry" locale="lao"></b-form-datepicker>
                            </b-input-group>
                        </b-list-group-item>
                        <b-list-group-item>
                            <b-form-checkbox switch v-model="fld.ca3" required>- ສຳມະໂນຄົວມີຮູບຂອງທ່ານຕິດຢູ່ ແລະ ຈ້ຳກາ ປກສ ແລ້ວບໍ່? </b-form-checkbox>
                        </b-list-group-item>
    
                        <b-list-group-item>
                            <b-form-checkbox switch v-model="fld.ca2" required>- ພໍ່ ແລະ ແມ່ຢູ່ໃນສຳມະໂນຄົວດຽວກັນກັບທ່ານບໍ່? </b-form-checkbox>
                        </b-list-group-item>
    
                    </b-list-group>
    
    
    
    
                </div>
                <div v-show="fld.age<18">
                    <label>-	ກໍລະນີ ພໍ່ແລະແມ່ບໍ່ຢູ່ໃນສຳມະໂນຄົວດຽວກັນ ທ່ານມີເອກະສານເລົ່ານີ້ບໍ່?</label>
                    <div>
                        <b-list-group>
    
                            <b-list-group-item>
                                <b-form-checkbox switch v-model="fld.ca4" :disabled="disabled_option" @change="oncheckvalid">- ໃບຍັ້ງຢືນການເກີດ(ຕ້ອງມີ) </b-form-checkbox>
                            </b-list-group-item>
                            <b-list-group-item>
                                <b-form-checkbox switch v-model="fld.ca5" :disabled="disabled_option">- ໃບແຕ່ງງານຂອງພໍ່ແມ່ (ຈາກຫ້ອງການພາຍໃນເມືອງ) </b-form-checkbox>
                            </b-list-group-item>
                            <b-list-group-item>
                                <b-form-checkbox switch v-model="fld.ca6" :disabled="disabled_option">- ໃບຢ່າຮ້າງ (ຈາກຫ້ອງການພາຍໃນເມືອງ)</b-form-checkbox>
                            </b-list-group-item>
                            <b-list-group-item>
                                <b-form-checkbox switch v-model="fld.ca7" :disabled="disabled_option">- ໃບຢັ້ງຢືນລູກນອກສົມລົດ(ຈາກນາຍບ້ານ) </b-form-checkbox>
                            </b-list-group-item>
                        </b-list-group>
    
    
    
    
                    </div>
    
                </div>
    
    
    
                <br>
                <div v-show="fld.age>18">
                    <b-list-group>
                        <b-list-group-item>
                            <b-form-checkbox switch v-model="fld.ca11" required>- ທ່ານມີບັດປະຈຳຕົວ ທ່ານມີແລ້ວບໍ່? </b-form-checkbox>
                        </b-list-group-item>
                        <b-list-group-item>
                            <b-form-checkbox switch v-model="fld.ca12" required>- ສຳມະໂນຄົວມີຮູບຂອງທ່ານຕິດຢູ່ ແລະ ຈຳກາ ປກສ ແລ້ວບໍ່? </b-form-checkbox>
                        </b-list-group-item>
                    </b-list-group>
    
    
    
                </div>
                <!-- <div v-show="fld.age>15">
                        <label>ວັນທີ່ໝົດອາຍຸບັດປະຈຳຕົວ:</label>
                        <b-input-group>
                            <b-form-datepicker v-model="fld.idcardexpriry" locale="lao"></b-form-datepicker>
                        </b-input-group>
                    </div> -->
    
    
                <b-row class="mt-3">
                    <b-col>
                        <b-button block variant="primary" type="submit"  >
                            <b-icon icon="check-circle"></b-icon> ຈອງບັດຄິວ </b-button>
                    </b-col>
                </b-row>
    
            </b-card>
        </b-form>
    </div>
</template>

<script>
import axios from "axios";
export default {

    created() {
        let info = JSON.parse(this.$route.query.c)
        this.fld.fname = info.fname
        this.fld.lname = info.lname
        this.fld.sex = info.sex
        this.fld.dob = info.dob
        this.fld.unit_id = info.unit_id
        this.fld.range_id = info.range_id
        this.fld.queue_date = info.queue_date


    },
    data() {
        return {
            disabled: true,
            disabled_option: false,
            fld: {
                fname: "",
                lname: "",
                sex: "",
                dob: "",
                "age": this.$route.params.age,
                "ca1": false,
                "ca2": false,
                "ca3": false,
                "ca4": false,
                "ca5": false,
                "ca6": false,
                "ca7": false,
                "ca8": false,
                "ca9": false,
                "ca10": false,
                "ca11": false,
                "ca12": false,
                "ca13": false,
                "ca14": false,
                "ca15": false,
                unit_id: "",
                queue_no: "",
                queue_date: "",
                range_id:""
            },
            selected: [], // Must be an array reference!
            options: [
                { text: 'ໃບຍັ້ງຢືນການເກີດ(ຕ້ອງມີ)', value: 'red' },
                { text: 'ໃບແຕ່ງງານຂອງພໍ່ແມ່', value: 'green' },
                { text: 'ໃບຢ່າຮ້າງ', value: 'yellow' },
                { text: 'ໃບຢັ້ງຢືນລູກນ້ອງສົມລົດ', value: 'blue' }
            ],
            dates: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

        }
    },
    methods: {
        goProcess() {
            //    let c=JSON.stringify(this.fld)
            if (this.fld.age < 15) {
                if ((this.fld.ca1 == false  || this.fld.ca3 == false)) {
                    if( this.fld.ca2 == false){
                        this.$router.push('/incorrect')
                        return
                    }
                    
                } 
            } else if (this.fld.age >= 15 && this.fld.age <= 18) {
                if(this.fld.ca8==false ||  this.fld.ca3==false ){
                   
                        this.$router.push('/incorrect')
                        return
                    
                }
                if(this.fld.ca8==true &&  this.fld.ca3==true &&  this.fld.ca2== false ){
                    if ((this.fld.ca4 == false && this.fld.ca5 == false && this.fld.ca6 == false && this.fld.ca7 == false)) {
                        this.$router.push('/incorrect')
                        return
                    }
                } 

                if(this.fld.ca8==false ||  this.fld.ca3==false ){
                    if ((this.fld.ca4 == false || this.fld.ca5 == false || this.fld.ca6 == false || this.fld.ca7 == false)) {
                        this.$router.push('/incorrect')
                        return
                    }
                }
                
            } else {
                if ((this.fld.ca11 == false || this.fld.ca12 == false)) {
                    this.$router.push('/incorrect')
                    return
                }
            }

            let params = {
                table: "queue_details",
                rows: this.fld
            }

            axios.post(this.$store.getters.getBaseUrl + "/request_queue_no", params)
                .then(res => {
                    this.$router.push('/qr/' + res.data.last_code)
                    console.log(res)
                })
                .catch(err => {
                    console.error(err);
                })


        },
        oncheckvalid() {

            if (this.fld.age < 15) {
                //   this.disabled= false
                if ((this.fld.ca1 == true && this.fld.ca3 == true)) {
                    console.log('test1')
                    // this.$router.push('/incorrect')
                    if (this.fld.ca2 == true) {
                        this.disabled = false
                        this.fld.ca4 = false
                        this.fld.ca5 = false
                        this.fld.ca6 = false
                        this.fld.ca7 = false
                        this.disabled_option = true
                    
                    } else {
                          console.log('test2')
                        this.disabled_option = false
                        this.disabled = false
                    }

                } else {
                     
                        this.disabled = true
                        this.disabled_option = false
                   
                    //    console.log('test3')



                }
            }else if(this.fld.age >= 15 && this.fld.age < 19){
                if (this.fld.ca2 == true) {
                        this.disabled = false
                        this.fld.ca4 = false
                        this.fld.ca5 = false
                        this.fld.ca6 = false
                        this.fld.ca7 = false
                        this.disabled_option = true
                    
                } 
            }
        }
    },
    filters: {
        dateString(d) {
            d.toLocaleString('lao', this.dates);
        }
    }

}
</script>

<style>
.nav-link a:active {
    font-weight: bold;
}
</style>